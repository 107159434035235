import * as React from "react"
import Layout from "../components/layout"
import { Color } from "../components/designToken"

const NotFoundPage = () => (
  <Layout>
    <style jsx>{`
      div {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      h1 {
        color: ${Color.corporate};
        font-size: 3rem;
      }

      a {
        display: block;
        margin-top: 20px;
      }
    `}</style>
    <div>
      <h1>404 NOT FOUND</h1>
      <a href="/">TOP</a>
    </div>
  </Layout>
)
export default NotFoundPage
